html {
  height: 100%;
}

#coming-soon .bodr {
  height: 100%;
  position: relative;
}

#coming-soon .bodr:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2a5555;
  opacity: 0.7;
  z-index: 1;
}

#coming-soon .mmmmnn {
  background: url(../Images/bg.mp4);
}

#coming-soon .bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

@media (pointer: coarse) and (hover: none) {
  #coming-soon body {
    background: url("../Images//bg-mobile-fallback.jpg") #2a5555 no-repeat center center scroll;
    background-size: cover;
  }
  #coming-soon body video {
    display: none;
  }
}

.masthead {
  position: relative;
  top:200px;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mkmk{
    position: relative;
    background: transparent;
    z-index: 2;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mkm{
    position: relative;
    z-index: 30;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}
/* .masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
} */
/* .masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1, .masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}
 */
@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 9rem;
  }
  .masthead .masthead-content h1, .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 65vw;
  }
}
.social-icons {
  position: relative;

  z-index: 2;
}
.nnnnnn{
    position: relative;
    top: 690px;
}
.nnn{
    margin-left: -30px;
    
}

.social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
}

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    top: 0;
    right: 2.5rem;
    width: auto;
  }
}

@media (max-width: 500px) {
    .mkmk img {
      content: url('../Images//Untitled-3.png'); /* Replace with your new image path */
      max-width: 100%; /* Ensure the image scales properly */
      height: auto; /* Maintain aspect ratio */
    }
    .social-icons {
        position: absolute;
        top: -30%;
        right: 2.5rem;
        left: 20px;
        width: auto;
      }

      
  }

  @media (max-width: 450px) {
    .social-icons {
      position: fixed; /* Keep the icons fixed at the bottom */
      bottom: 1rem; /* Position 1rem from the bottom of the viewport */
      left: 50%; /* Position the center of the container at the middle of the viewport */
      transform: translateX(-50%); /* Offset the container by 50% of its own width to center it */
      right: auto; /* Remove right positioning */
      width: auto; /* Adjust width as needed */
    }
  }
  
  @media (max-width: 420px) {
    .social-icons {
      position: fixed; /* Keep the icons fixed at the bottom */
      bottom: 1rem; /* Position 1rem from the bottom of the viewport */
      left: 50%; /* Position the center of the container at the middle of the viewport */
      transform: translateX(-50%); /* Offset the container by 50% of its own width to center it */
      right: auto; /* Remove right positioning */
      width: auto; /* Adjust width as needed */
    }
  }
  
  @media (max-width: 390px) {
    .mkmk img {
      content: url('../Images/Untitled-4.png'); /* Replace with your new image path */
      max-width: 100%; /* Ensure the image scales properly */
      height: auto; /* Maintain aspect ratio */
    }
    .social-icons {
      position: fixed; /* Keep the icons fixed at the bottom */
      bottom: 1rem; /* Position 1rem from the bottom of the viewport */
      left: 50%; /* Position the center of the container at the middle of the viewport */
      transform: translateX(-50%); /* Offset the container by 50% of its own width to center it */
      right: auto; /* Remove right positioning */
      width: auto; /* Adjust width as needed */
    }
  }
  
  @media (max-width: 350px) {
    .social-icons {
      position: fixed; /* Keep the icons fixed at the bottom */
      bottom: 1rem; /* Position 1rem from the bottom of the viewport */
      left: 50%; /* Position the center of the container at the middle of the viewport */
      transform: translateX(-50%); /* Offset the container by 50% of its own width to center it */
      right: auto; /* Remove right positioning */
      width: auto; /* Adjust width as needed */
    }
  }
  