.list-group-item {
    padding: 0; /* Remove default padding if needed */
  }
  
  .flexi{
    display: flex;
    flex-wrap: wrap; 
  }
  
  .btn1 {
    margin-right: 0.5rem; 
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: none;
    
  }