body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Ensure full width container on smaller screens */
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

/* Style for the content box */
.content-box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    
}

/* Sidebar styling */
.sidebar {
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
}

/* Adjust button styles for better mobile experience */
.btn {
    border-radius: 5px;
}

/* Input styling */
.inp {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

/* Textarea and editor content */
.editor-content {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .content-box, .sidebar {
        margin-bottom: 20px;
    }

    .btn-group {
        flex-direction: column;
    }

    .btn-group .btn {
        margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
  .content-box, .sidebar {
      margin-bottom: 20px;
  }

  .btn-group {
      flex-direction: column;
      margin: 2px;
  }

  .btn-group .btn {
      margin-bottom: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img{
  height: 50px;
  width: 200px;
}





.img1{
  height: 10vh;

  color: rgba(255, 0, 0, 0.63);
}
@media (max-width:600px) {

.img1{
  height: 70px;
  width: 210px;
}
}
/* Footer End */


/* Home Start */
.homeimg{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527) ,rgba(0, 0, 0, 0.527) ), url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4LnUCp2VxTglo5GXUXYub6Fc5qQzvGsSCkA&s);
  
  background-size: cover;
  background-repeat: no-repeat;
}
.inp1{
  width: 50%;
  height: 3rem;
  padding-left: 60px;
  border-radius: 5px;
  border: none;
  background-color: rgb(0, 102, 255);
  color: white !important;
  background-image: url(https://img.icons8.com/?size=100&id=7695&format=png&color=FFFFFF) !important;
  background-position: 1px 1px;
  background-size: contain; 
  background-repeat: no-repeat;
}
.inp1::placeholder{
  color: white !important;
}

.div1{
  background-color: white;
  box-shadow: 10px 10px 40px black;
}
.btn-1{
  padding: 4px;
  border: none;
  border-radius: 5px;
}
.div-2{
  box-shadow: 2px 2px 30px black;
  background-color: white;
}
.btnn{
  background-color: rgb(255, 123, 0);
  border: none;
  border-radius: 5px;
  display: block;
  margin: auto;
  padding: 10px 30px 10px 30px;
}
.cen{
  text-align: center;
}
.bt-1{
  background-color: rgba(173, 173, 173, 0.527);
  border-radius: 5px 5px 0px 0px !important;
}
.bt-3{
  border-radius: 5px 5px 0px 0px !important;
  border: 1px solid rgba(128, 128, 128, 0.699);
  border-bottom: none;

}
.bt-3:hover{
  border: 1px solid black;
}
.bt-2{


padding-top: 10px;
}
.tags{
  text-align: center;
}
.btn-09{
  border: 1px solid grey;
  background: transparent ;
  border-radius: 5px;
  padding: 2px;
  margin: 2px;
}



/* Signin start */
.gradient-custom-2 {
  background-size: cover;
  background-position: no-repeat;
   background-image: linear-gradient(to right, rgb(0, 102, 255, 0.466), rgba(0, 102, 255, 0.466)),url(./Images//person_3_sm.jpg);
 
  }
  .button{
    border-left: 1px solid grey;
  }
  .image-upload-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px;
  }
/*   .inp{
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 15px black;;
  } */
  
  .input-group-text {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-right: 0;
    padding: 0.375rem 0.75rem;
 
  }
  .input11{
    box-shadow: 2px 2px 15px black;
  }
  .form-control {
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .btn11 {
   border-radius: 0px;

  }
  .div2{
  box-shadow: 10px 10px 40px black;
  }
  .inpp{
  border: 1px solid grey;
  
    color: black !important;
   
  }
  .tagz{
    border-radius: 5px ;
    border: none;
    box-shadow: 1px 1px 10px black;
  }
  .col3 {
    height: 150px; /* Set a fixed height for all badge containers */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
  
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .bgcs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .col-3.col3 span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main p {
    height: 100%; /* Make sure the height is consistent */
    display: flex;
    align-items: center;
  }
  

  .out{
    height: 150px; /* Set a fixed height for all badge containers */
    display: flex;
    flex-direction: column;
    justify-content: center; 
    box-shadow: 1px 1px 10px black;
    border-radius: 5px;
    border-left: 2px solid rgb(255, 123, 0) ;
  }
  @media (max-width: 750px) {
    .bhbh{
      width: 100% !important;
    }
  
    .out{
      height: 150px; /* Set a fixed height for all badge containers */
      display: flex;
      flex-direction: column;
      justify-content: center; 
      font-size: small;
      box-shadow: 1px 1px 10px black;
      border-radius: 5px;
      border-left: 2px solid rgb(255, 123, 0) ;
    }
  
  }

  @media (max-width: 620px) {
  
    .out{
      height: 150px; /* Set a fixed height for all badge containers */
      display: flex;
      flex-direction: column;
      justify-content: center; 
      font-size: x-small;
      box-shadow: 1px 1px 10px black;
      border-radius: 5px;
      border-left: 2px solid rgb(255, 123, 0) ;
    }
 

  }
 .nnn{

 
  text-align: left;
}

@media (max-width:520px) {
  .nnn{
    
    width: 250px;
    
  }

}
@media (max-width:380px) {
  .nnn{
    
    width: 210px;
    
  }

}
@media (max-width:320px) {
  .nnn{
    
    width: 160px;
    
  }

}
.div5{
background-color: white;
  box-shadow: 10px 10px 40px black;
}

.col3{
  border-right: 1px solid grey;
}
.bgc{
   height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  background-color:rgb(255, 123, 0) ;
  border-radius: 5px;
}
.bgcs{

  border-radius: 5px;
}
.main{
  border: 1px solid grey;
  border-radius: 5px;
  box-shadow: 1px 1px 10px grey;
}
.paragraph {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
}

.paragraph.visible {
  opacity: 1;
  max-height: 100px; 
}
.head{
  position: absolute;
}
.bg1{
  border-left: 5px solid rgb(0, 102, 255) ;
  box-shadow: 1px 0px 10px black;
  border-radius: 5px;
  display: inline;
  justify-content: center;
}
.bg2{
 border: 1px solid grey;
 border-radius: 0px 0px 5px 5px;
 border-top: none;
 
}
.div3{
  background-color: white;
  box-shadow: 10px 10px 40px black;
}
.primary{
  background-color:rgb(0, 102, 255, 0.466) ;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bord{
  border-left: 4px solid rgb(0, 102, 255);
  border-radius: 5px;
}
.btn0{
  background-color: rgba(128, 128, 128, 0.555);
  border-radius: 5px;
}

.bord2{
  border: 1px solid grey ;
  border-radius: 5px 5px 0px 0px;
  border-right:3px solid orange ;
  display: inline;
  justify-content: center;
  align-items: center;
}
.bord-1{
  border: 1px solid grey ;
  border-radius: 0px 0px 5px 5px;
  border-top:none ;
  border-left: 3px solid green ;
  display: inline;
  justify-content: center;
  align-items: center;
}
.bord1{
  border: 1px solid grey ;
  border-radius: 0px 0px 5px 5px;
  border-top:none ;
  border-right: 3px solid rgb(0, 102, 255) ;
 
}

.bord-2{
  border: 1px solid grey ;
  border-radius: 5px 5px 0px 0px;
  border-left: 3px solid rgb(0, 102, 255) ;
  display: inline;
  justify-content: center;
  align-items: center;
}
.back4{
  background-color: white;
  box-shadow: 2px 2px 10px black;
}
.inp2{
  box-shadow: 2px 2px 10px black;
border: none ;
border-radius: 5px;
border-left: 3px solid rgb(0, 102, 255) ;
background-image: url(https://img.icons8.com/?size=100&id=108791&format=png&color=000000) !important;
  background-position: 2px 1px;
  background-size: contain; 
  background-repeat: no-repeat;
}
.tool{
 background-color: rgba(128, 128, 128, 0.534);
}
.btn-block{
  background-color: rgb(255, 123, 0);
  border: none;
  border-radius: 5px;
  padding: 7px;
}
.bgg{
  box-shadow: 2px 2px 10px black;

}
.btn-12{
  background-color:rgba(0, 102, 255, 0.596) ;
  color: white;
}
.btn-12:hover{
background-color:rgb(0, 102, 255) ;
}
.hei{
 height: 250vh;
}
.auto-height-div {
  min-height: 150vh;

}
.bbbh{
  position:relative;
  left: -20px;
}
.prof{
 
  border-radius: 5px;
}
.prof1{
  border-top: 2px solid white;
  border-radius: 5px;
}
.ques{
  border-top: 2px solid white;
  border-bottom: 2px solid white;

}
.answ{
  
  border-bottom: 2px solid white;
  border-radius: 5px;
}

/* .btnn19:hover{
  background: white !important;
  color: rgb(0, 102, 255);
}
.btnn19:active{
  background: white !important;
  color: rgb(0, 102, 255);
} */

/* Default button styles */
.btnn19 {
  background-color: transparent; 
  color: white;                  

  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.1s, color 0.3s;
  text-decoration: none;
}

.btnn19.active {
  background-color: white !important; 
  color: rgb(0, 102, 255);    
 border-radius: 10px;
  border: none;
}



.get-in-touch {
  max-width: 800px;
  margin: 50px auto;
  position: relative;

}
.get-in-touch .title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 3.2em;
  line-height: 48px;
  padding-bottom: 48px;
     color: #5543ca;
    background: #5543ca;
    background: -moz-linear-gradient(left,rgb(0, 102, 255)  0%,rgb(0, 102, 255) 100%) !important;
    background: -webkit-linear-gradient(left,rgb(0, 102, 255)  0%,rgb(0, 102, 255) 100%) !important;
    background: linear-gradient(to right,rgb(0, 102, 255)  0%,rgb(0, 102, 255)  100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.contact-form .form-field {
  position: relative;
  margin: 32px 0;
}
.contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: rgb(0, 102, 255);
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.contact-form .input-text:focus {
  outline: none;
}
.contact-form .input-text:focus + .label,
.contact-form .input-text.not-empty + .label {
  -webkit-transform: translateY(-0px);
          transform: translateY(-0px);
}
.contact-form .label {
  position: absolute;
  left: 20px;
  bottom: 11px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: rgb(0, 102, 255);
  cursor: text;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, 
  -webkit-transform .2s ease-in-out;
}
.contact-form .submit-btn {
  display: inline-block;
  background-color: #000;
   background-image: linear-gradient(125deg,#064497,#0b6ae7);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width:200px;
  cursor: pointer;
}

/* .card{
  box-shadow: 2px 2px 10px black;
} */
.sidebtn{
  border-right: 2px solid black;
  border-radius: 0;
}
.text1{
  text-decoration: none;
  color: black;
  font-size:large;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown-menu {
  display: none;
  position:absolute;
  background-color: white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 0px;
  border-radius: 4px;
  margin-left: -240px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.btn {
  width: 100%;
  text-align: center;
}
.iamge1{
   height: 2vh !important;
   max-width: 30%;
  border: 1px solid green;
  border-radius: 20px;
}
@media  (max-width: 768px) {
  .iamge1{
    height: 10vh;
   border: 1px solid green;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-self: start;
 }
}
.answ1{
  
  border: 1px solid grey;
}
.bord3{
  border: 1px solid grey;
  box-shadow: 1px 1px 10px black;
}
/* src/components/Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures the loader stays on top */
}
  


.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 570px) {
  
  
  .mdi{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .meda{
    padding-top: 15px;
  }
}

@media (max-width: 320px) {
  
  
  .mdi{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
}

@media (max-width: 532px) {
  .mkkkk{
    display: flex;
    flex-direction: column;
    
  }
  .smaall{
    font-size: x-small;
    text-wrap:wrap;
  }
}
@media (max-width: 500px) {
  .container-flex .btn-sm {
    font-size: 12px;
    padding: 5px 8px;
    margin: 2px;
  }

  .container-flex .polls-container {
    flex: 1;
    text-align: center;
    margin: 0;
  }

  .container-flex .polls-text {
    font-size: 12px;
    padding: 5px 8px;
  }

  .container-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .container-flex .btn-sm,
  .container-flex .polls-text {
    flex: 1 1 auto;
    margin-bottom: 5px;
  }
}

.custom-modal {
  width: 50vw;
  max-width: 50vw; 
  margin: auto; 
}

@media (max-width: 768px) {
  .custom-modal  {
      width: 90vw;
      max-width: 90vw; 
  }
}

.homeimg {
  position: relative; /* Make sure the parent is positioned for the absolute positioning of the dropdown */
}

.inp1 {
  width: 50%; /* Ensure the input takes up the full width */
}
.suggestions-list::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}
.suggestions-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

.suggestions-list::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Optional: round the corners of the scrollbar thumb */
}

.suggestions-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color when hovered */
}

.suggestions-list::-webkit-scrollbar-button {
  display: none; /* Hide the arrows */
}

.suggestions-list {
  position: absolute !important;
  width: 50%;
  top: 100% !important; /* Position it below the input */
  left: 25% !important;
  right: 0 !important;
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  box-shadow: 2px 2px 10px rgb(0, 0, 0)!important;
  z-index: 1000 !important;
  max-height: 300px !important;
  color: black;
  overflow-y: auto !important;
  margin-top: 2px !important  ; 
}

.suggestions-list h5 {
  margin: 0;
  padding: 8px;
  font-size: 14px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
}

.suggestions-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
.space{
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  box-shadow: 1px 1px 3px black;

}
.olll{
  display: flex;
  justify-content: center;
  align-items: center;
}
.jaba{
  margin-top: 10px;
  background-color: rgba(202, 202, 202, 0.664);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 20%;
  margin-bottom: 0px;
}
@media (max-width: 614px) {
  .jaba{
    margin-top: 5px;
    background-color: rgba(202, 202, 202, 0.664);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    margin-bottom: 0px;
  }
}

.jaba3{
  margin-left: 10px !important;
}
.jaba2{
  background-color: white;
  box-shadow: 2px 2px 5px black;
  display: flex;
  
}
.bordii{
  border: 1px solid grey;
  border-radius: 10px;
}
.img222 {
  display: block;

  object-fit: cover;
  margin: 0 auto;
  text-align: center;
  font-size: 16px; /* Adjust font size if needed */
  line-height: 150px; /* This should match the height of the image to center the text vertically */
  background-color: #f0f0f0; /* Fallback background */
}
.bgggg{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

}
@media (max-width:600px) {
  .bgggg {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;;

  }}

.main-content{
  padding-top: 10px;
  padding-left: 30px;
}
@media (max-width:500px) {
  .main-content{
    padding-top: 10px;
    margin-left: -200px !important;
    z-index: -1 !important;
  }
  .main-content1{
    padding-top: 10px;
    margin-left: -400px !important;
    z-index: -1 !important;
  }

}

.kkkk{
  width: 90dvw;
 height: 100dvh;
 border: 0;
}
@media (max-width:399px) {
  .kkkk{
    width: 135dvw;
   height: 100dvh;
   border: 0;
   padding-left: 30%;
  }
}

@media (max-width:489px) {
  .kkkk{
    width: 135dvw;
   height: 100dvh;
   border: 0;
   padding-left: 30%;
  }
}


.mamaaa{
  margin-right: 0.5rem !important;
  margin-left: 2rem !important;
}
#referral-url {
  flex: 1;
  border: 1px solid #ddd;
  padding: 10px;
  margin-right: 10px;
  border-radius: 4px;
}

#copy-btn {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

#copy-btn:hover {
  background-color: #0056b3;
}

.ssss{
 border-bottom: 1px solid grey;
}
.hbb:hover{
background-color:#046edf ;
color: white;
}

html, body {
  overflow-x: hidden;
  max-width: 100%;
}