/* Pagination Container */
.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .pagination-wrapper {
    display: flex;
    align-items: center;
    /* background-color: #f3f4f6; */
    border-radius: 0.5rem;
    padding: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  /* Pagination Buttons */
  .pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 0.375rem;
    padding: 0.25rem;
    color: #4b5563;
  }
  
  .pagination-btn:hover:not(:disabled) {
    background-color: #e5e7eb;
  }
  
  .pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Page Number Buttons */
  .page-number-btn {
    min-width: 2rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease-in-out;
    margin: 0 0.125rem;
  }
  
  .page-number-btn:not(.active):hover {
    background-color: #e5e7eb;
  }
  
  .page-number-btn.active {
    background-color: #3b82f6;
    color: white;
    font-weight: 600;
  }
  
  /* Ellipsis */
  .pagination-ellipsis {
    color: #9ca3af;
    padding: 0 0.25rem;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 640px) {
    .pagination-wrapper {
      padding: 0.25rem 0.5rem;
    }
  
    .page-number-btn {
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
    }
  }