.imggg {
  height: 10vh;
}

.text-1 {
  margin-left: -70px;
}

.back {
  background-color: rgba(0, 102, 255, 0.411);
  color: white;
  height: 9vh;
  border-radius: 5px;
}

.btn3 {
  display: block;
  padding-top: 10px;
}

.btn-2 {
  background-color: rgb(255, 123, 0);
}

.btn-2:hover {
  background-color: darkgoldenrod;
}

/* Extra small devices (phones, 0px and up) */
@media (max-width: 575.98px) {
  .imggg {
    height: 8vh;
  }

  .text-1 {
    margin-left: 0;
    text-align: center;
  }

  .back {
    height: auto;
    padding: 10px;
    text-align: center;
  }

  .btn3 {
    text-align: center;
  }

  .btn-2 {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .imggg {
    height: 9vh;
  }

  .text-1 {
    margin-left: -30px;
  }

  .back {
    height: auto;
    padding: 10px;
  }

  .btn3 {
    text-align: center;
  }

  .btn-2 {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .imggg {
    height: 10vh;
  }

  .text-1 {
    margin-left: -50px;
  }

  .back {
    height: auto;
    padding: 10px;
  }

  .btn3 {
    text-align: center;
  }

  .btn-2 {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .imggg {
    height: 10vh;
  }

  .text-1 {
    margin-left: -60px;
  }

  .back {
    height: 9vh;
  }

  .btn3 {
    text-align: center;
  }

  .btn-2 {
    width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .imggg {
    height: 10vh;
  }

  .text-1 {
    margin-left: -70px;
  }

  .back {
    height: 9vh;
  }

  .btn3 {
    text-align: center;
  }

  .btn-2 {
    width: 100%;
  }
}

.btn-block {
  background-color: rgb(255, 123, 0);
  border: none;
  border-radius: 5px;
  padding: 7px;
}
/* HomeSlid.css */

/* Notification dropdown adjustments */
.notification-dropdown {
  position: absolute; /* Ensure the dropdown is positioned correctly */
  z-index: 10; /* Ensure it overlays the cards */
  right: 0;
  top: 50px; /* Adjust this value as needed */
  width: 250px; /* Width of the dropdown */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Cards container */
.card-container {
  margin-top: 80px; /* Adjust this value to push the cards below the dropdown */
}

.card1 {
  position: relative;
  z-index: 1; /* Ensure the cards are behind the dropdown */
  margin-bottom: 20px;
}

/* Polls container */
.polls-container {
  display: flex;
  justify-content: start !important;
  align-items: center !important;
}

/* Button and other elements adjustments */
.polls-text1 {
  cursor: default;
  background-color: rgba(160, 160, 160, 0.712);
  border-radius: 5px;
  text-align: center;
  width: 5vw;
  font-weight: bold;
}
.polls-text {
  cursor: pointer;
  width: 10vw;
  font-weight: bold;
}
.polls-text1 {
  cursor: default !important;
  background-color: rgba(160, 160, 160, 0.712);
  width: 10vw;
  font-weight: bold;
}
@media (max-width: 500px) {
  .polls-text1 {
    cursor: default !important ;
    background-color: rgba(160, 160, 160, 0.712);
    width: 10vw;
    font-weight: bold;
    font-size: 0.7em;
  }
  .polls-container {
    display: flex;
    gap: 10px !important;
  }
}

.conma .polls-container.pols.polll {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.pol {
  text-align: center !important;
  cursor: pointer;
  width: 10vw;
  font-weight: bold;
}
.conma .polls-container.pol {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.polls-content {
  display: none;
  margin-top: 10px;
}

.polls-content.open {
  display: block;
}

.height1 {
  height: auto;
}

.btn {
  margin-left: 5px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

/* Loader adjustments */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-container {
    margin-top: 120px; /* Adjust for smaller screens */
  }

  .polls-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .notification-dropdown {
    top: 60px; /* Adjust for smaller screens */
    right: 10px;
  }
}
/* HomeSlid.css */

@media (max-width: 500px) {
  .conma {
    flex-direction: column; /* Stack the items in a column on small screens */
    align-items: stretch; /* Ensure buttons take full width */
  }

  .polls-container,
  .d-flex.flex-row {
    width: 100%; /* Make each container take the full width */
    text-align: center; /* Center the text */
    margin-bottom: 10px; /* Add some space between the buttons */
  }

  .d-flex.flex-row {
    justify-content: center; /* Center the buttons in the flex row */
  }

  .polls-text {
    width: 100%; /* Ensure the text inside takes full width */
  }

  .polls-text1 {
    width: 100%; /* Ensure the text inside takes full width */
  }
  .btn {
    width: 100%; /* Ensure buttons take full width */
  }
}
.iamge1 {
  height: 50px !important;
  width: 50px !important;
  display: flex;
  background-color: white;
  border: 1px solid black;
  border-radius: 20%;
}

/* Responsive Adjustments for Small Screens */
@media (max-width: 768px) {
  .iamge1 {
    height: 10vh;
    border: 1px solid green;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-self: start;
  }
  .HomeSlid {
    padding: 10px !important;
  }

  /* Compact card layout */
  .card {
    margin-bottom: 15px;
  }

  .card-body {
    padding: 15px !important;
  }

  /* Optimize header section for small screens */
  .card-body
    > .d-flex.justify-content-between.align-items-center.mb-3.mt-3.position-relative {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .card-body
    > .d-flex.justify-content-between.align-items-center.mb-3.mt-3.position-relative
    > div {
    width: 100%;
    border-right: none !important;
    margin: 5px 0 !important;
    padding: 0 !important;
  }

  /* Compact company and tag sections */
  .card-body div[style*="flexBasis"] {
    flex-basis: 100% !important;
    text-align: left;
    margin: 5px 0 !important;
  }

  /* Optimize action buttons */
  .d-flex.justify-content-between.align-items-center.mt-2 {
    flex-direction: column;
    align-items: stretch !important;
    gap: 10px;
  }

  .d-flex.align-items-center.gap-2 {
    justify-content: space-between;
    width: 100%;
  }

  /* Responsive image sizing */
  .profile-picture {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
  }

  /* Compact text sizes */
  .card-body p {
    font-size: 12px !important;
  }

  .card-body h5 {
    font-size: 16px !important;
  }

  /* Load More Button */
  .btn.w-100 {
    width: 100% !important;
    margin-top: 10px;
  }

  /* Tags */
  .badge {
    margin-bottom: 5px;
    font-size: 10px !important;
  }
}

/* Extra Small Devices (Phones) */
@media (max-width: 480px) {
  .card-body {
    padding: 10px !important;
  }

  .d-flex.align-items-center {
    flex-wrap: wrap;
  }

  /* Even more compact text */
  .card-body p {
    font-size: 11px !important;
  }

  .card-body h5 {
    font-size: 14px !important;
  }

  /* Tighter action buttons */
  .d-flex.justify-content-between.align-items-center.mt-2 > div {
    flex-wrap: wrap;
    gap: 5px;
  }

  /* Compact icon sizes */
  svg {
    width: 16px;
    height: 16px;
  }
}

.views-icon {
  margin-right: "10px";
  display: "inline-block";
}

@media (max-width: 768px) {
  .comments-text,
  .views-icon {
    display: none;
  }
}
